const scriptAttributes = (
  document.currentScript?.getAttributeNames() || []
).reduce((acc, name) => {
  // eslint-disable-next-line security/detect-object-injection
  acc[name] = document.currentScript.getAttribute(name);

  return acc;
}, {});

// asynchronous start boundary for webpack dynamic module loading
import('./index').then(({ startApp }) => startApp(scriptAttributes));